import React from 'react';

import "./block.scss";

const Block = ({data}) => {
    return (
        <div className="block">
            <a href={data.url} target="_blank" rel="nofollow">
                <figure>
                    <video
                        width="443"
                        height="1024"
                        muted
                        playsInline
                        onMouseEnter={e => {
                            e.target.play()
                        }}
                        onMouseLeave={e => {
                            e.target.pause();
                            e.target.currentTime = 0
                        }}
                        src={data.video}
                        title={`Video: ${data.title}`}
                    >
                        <source src={data.video} type="video/mp4"/>
                    </video>
                </figure>
                <h2>{data.logo}</h2>
            </a>
        </div>
    );
};

export default Block;