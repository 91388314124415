import React from "react";

import dynamofilmVideo from "../videos/dynamogroup-flirtini.mp4";
import {ReactComponent as DFilmLogo} from "../images/dynamofilm-logo.svg";

import dynamodigitalVideo from "../videos/dynamogroup-ipa.mp4";
import {ReactComponent as DDigitalLogo} from "../images/dynamodigital-logo.svg";

import dynamopostVideo from "../videos/dynamogroup-reel.mp4";
import {ReactComponent as DPostLogo} from "../images/dynamopost-logo.svg";

export const data = {
    links : [
        {
            title: "Dynamo Film",
            video: dynamofilmVideo,
            logo: <DFilmLogo />,
            url: "https://dynamofilm.pl/"
        },
        {
            title: "Dynamo Digital",
            video: dynamodigitalVideo,
            logo: <DDigitalLogo />,
            url: "https://dynamofilm.pl/dynamo-digital-studio"
        },
        {
            title: "Dynamo Post",
            video: dynamopostVideo,
            logo: <DPostLogo />,
            url: "https://dynamofilm.pl/dynamo-post"
        }
    ]
};