import React from "react";
import './App.scss';
import {data} from "./data";
import Block from "./components/Block";

function App() {
  return (
    <div className="App">
      <h1 aria-hidden="false">Dynamo group hub</h1>
      <div className="row boxed start-xs center-sm top-xs middle-sm">
          {
              data.links.map(
                  (block,index) =>
                  <Block key={index}  data={block} />
              )
          }
      </div>
    </div>
  );
}

export default App;
